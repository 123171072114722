/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
/* eslint-disable no-useless-escape */
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyError, showVueToast } from '@/utils'

const CONFIG = {
  companyName: 'Company Name',
  picName: 'PIC Name',
  phoneNumber: 'PIC Phone Number',
  picEmail: 'Corporate Email',
  checkboxInput: 'Checkbox'
}

export default {
  name: 'register-mitra',
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Checkbox: () => import(/* webpackChunkName: "checkbox" */ '@/components/forms/Checkbox'),
    Email: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Email'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data() {
    return {
      errors: [],
      formPayload: {
        companyName: '',
        phoneNumber: '',
        picEmail: '',
        picName: ''
      },
      errorCheckbox: '',
      phoneInputType: 'number',
      visibleModal: false,
      cooperation: [
        {
          id: 'EDUCATION',
          label: 'Education',
          checked: false,
          disabled: false
        },
        {
          id: 'LMS',
          label: 'LMS',
          checked: false,
          disabled: false
        },
        {
          id: 'MANPOWER',
          label: 'Manpower',
          checked: false,
          disabled: false
        },
        {
          id: 'MARKETPLACE',
          label: 'Marketplace',
          checked: false,
          disabled: false
        },
        {
          id: 'PROJECT',
          label: 'Project',
          checked: false,
          disabled: false
        },
        {
          id: 'LP_CURATION',
          label: 'LP Curation',
          checked: false,
          disabled: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'adminGeneral']),
    companyName() {
      return this.formPayload.companyName
    },
    picName() {
      return this.formPayload.picName
    },
    email() {
      return this.getOnlyText(this.formPayload.picEmail)
    },
    phoneNumber() {
      return this.formPayload.phoneNumber
    },
    cooperationChecked() {
      return this.cooperation.filter((item) => item.checked)
    }
  },
  watch: {
    cooperationChecked() {
      this.cooperationChecked.length < 1 ? (this.errorCheckbox = 'Please select at least one!') : (this.errorCheckbox = '')
    },
    companyName(value) {
      value === '' ? (this.errors['companyName'] = "Company Name can't be empty!") : (this.errors['companyName'] = '')
    },
    picName(value) {
      value === '' ? (this.errors['picName'] = "PIC Name can't be empty!") : (this.errors['picName'] = '')
    },
    email(value) {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      value === '' ? (this.errors['picEmail'] = "Email can't be empty!") : (this.errors['picEmail'] = '')
      !emailPattern.test(this.formPayload.picEmail) ? (this.errors['picEmail'] = 'Incorrect email format!') : (this.errors['picEmail'] = '')
    },
    phoneNumber(value) {
      if (value.length >= 15) {
        this.formPayload.phoneNumber = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value[0] !== '0' || value[1] !== '8') {
        this.errors['phoneNumber'] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.errors['phoneNumber'] = ''
      }
    }
  },
  methods: {
    ...mapActions('common', ['sendWhatsApp']),
    ...mapActions('auth', ['registerMitra']),
    getOnlyText(str) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = str
      return tmp.textContent.split(' ').join('') || tmp.innerText.split(' ').join('') || ''
    },
    allValidation() {
      let pass = true

      const error = isNotEmptyError(this.formPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})

      if (this.formPayload.phoneNumber === '') {
        this.errors['phoneNumber'] = "PIC Phone Number can't be empty!"
        pass = false
      } else if (this.formPayload.phoneNumber[0] !== '0' || this.formPayload.phoneNumber[1] !== '8') {
        this.errors['phoneNumber'] = 'Must start with 08xxxx'
        pass = false
      } else if (this.formPayload.phoneNumber.length < 10) {
        this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
        pass = false
      }

      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.formPayload.picEmail === '') {
        this.errors['picEmail'] = "Email can't be empty!"
        pass = false
      } else if (!emailPattern.test(this.formPayload.picEmail)) {
        this.errors['picEmail'] = 'Incorrect email format!'
        pass = false
      }

      if (this.cooperationChecked.length === 0) {
        this.errorCheckbox = 'Please select at least one!'
        pass = false
      }
      return pass
    },
    showModal() {
      if (!this.allValidation()) return
      this.visibleModal = true
    },
    hideModal() {
      this.visibleModal = false
    },
    doRegister() {
      const allianceNeeds = this.cooperationChecked.map((item) => {
        return { name: item.id }
      })
      const payloads = {
        companyName: this.formPayload.companyName,
        mitraKerjasamas: allianceNeeds,
        phoneNumber: this.formPayload.phoneNumber,
        picEmail: this.getOnlyText(this.formPayload.picEmail),
        picName: this.formPayload.picName,
        contacts: [{
          picName: this.formPayload.picName,
          picEmail: this.getOnlyText(this.formPayload.picEmail),
          phoneNumber: this.formPayload.phoneNumber,
          primaryContact: true
        }]
      }
      this.registerMitra({
        payloads
      })
        .then((res) => {
          if (res.code === 200) {
            showVueToast('Registration Successful, please check your email!', 'success', 2000)
            this.formPayload = {
              companyName: '',
              phoneNumber: '',
              picEmail: '',
              picName: ''
            }
            setTimeout(() => {
              this.$router.push('/thank-for-registration')
            }, 200)
          } else if (res.code === 422) {
            showVueToast('Email already registered!', 'error', 3000)
          } else if (res.code === 500) {
            showVueToast('Company name is already in use!', 'error', 3000)
          } else {
            showVueToast('Registration Failed, please try again or contact admin!', 'error', 3000)
          }
        })
        .catch(() => {
          showVueToast('Registration Failed, please try again or contact admin!', 'error', 3000)
        })
    },
    privacy() {
      this.$router.push('/privacy-policy')
    },
    changeCheck(id) {
      switch (id) {
        case 'EDUCATION':
          this.cooperation[0].checked = !this.cooperation[0].checked
          break
        case 'LMS':
          this.cooperation[1].checked = !this.cooperation[1].checked
          break
        case 'MANPOWER':
          this.cooperation[2].checked = !this.cooperation[2].checked
          break
        case 'MARKETPLACE':
          this.cooperation[3].checked = !this.cooperation[3].checked
          break
        case 'PROJECT':
          this.cooperation[4].checked = !this.cooperation[4].checked
          break
        case 'LP_CURATION':
          this.cooperation[5].checked = !this.cooperation[5].checked
          break
      }
    },
    sendMessage() {
      this.sendWhatsApp({ number: this.adminGeneral, topic: 'Mitra%20G2Academy.' })
    },
    sendEmail() {
      window.open('mailto:corp@g2academy.co', '_self')
    },
    login() {
      window.open(process.env.VUE_APP_BASE_DOMAIN_ADMIN)
    }
  },
  mounted() {
    if (this.$route.query.assign) {
      this.cooperation.forEach((item) => {
        if (item.id === this.$route.query.assign) {
          item.checked = true
        }
      })
    }
  }
}
